body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent !important;
  color: black !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown > * {
  all: revert;
}

.notion.notion-app {
  background: transparent !important;
}

.notion.notion-frame {
  background: transparent !important;
}

/* Ensure Notion text justification remains as defined in Notion */
.notion.notion-text,
.notion.notion-page-content {
  text-align: initial !important;
}

/* Optional: Remove background if needed */
.notion.notion-frame {
  background: transparent !important;
}
